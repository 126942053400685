<template>
  <div class="osk-celebrity-card">
    <img
      v-if="celebrityComp.avatarPath"
      :src="celebrityComp.avatarPath"
      :alt="String(celebrityComp.id ?? 'avatar')"
      class="osk-celebrity-card__img"
    >

    <account-o-community-badge
      v-if="celebrityComp.communityBadge"
      :model-value="celebrityComp.communityBadge"
      :user="celebrityComp"
      with-dialog
      class="osk-celebrity-card__badge"
      border-color="#f5f5f5"
    />

    <div
      v-if="celebrityComp.name"
      class="osk-celebrity-card__name"
    >
      {{ celebrityComp.name }}
    </div>

    <div
      v-if="celebrityComp.followersCount && Number(celebrityComp.followersCount) !== 0"
      class="osk-celebrity-card__subscribers"
    >
      <template v-if="Number(celebrityComp.followersCount)">
        {{ $t('uikit.celebrity.celebrityCard.followersCount', Number(celebrityComp.followersCount)) }}
      </template>
      <template v-else>
        {{ celebrityComp.followersCount }} {{ $t('uikit.celebrity.celebrityCard.followersCount') }}
      </template>
    </div>

    <div
      v-if="celebrityComp.productsCount"
      class="osk-celebrity-card__productsCount"
    >
      {{ $t('uikit.celebrity.celebrityCard.productsCount', celebrityComp.productsCount) }}
    </div>

    <UikitButton
      :to="`/profile/${celebrityComp.id}`"
      outline
      full-width
      :size="!isMounted || isDesktop ? 'm' : 's'"
      class="osk-celebrity-card__button"
      @click="handleClick"
    >
      {{ $t('uikit.celebrity.celebrityCard.toProducts') }}
    </UikitButton>
  </div>
</template>

<script lang="ts" setup>
import { SocialAccountDTO, CelebrityDTO, PublicProfileDTO } from '~/restAPI/data-contracts';

const props = defineProps<{
  data: SocialAccountDTO | CelebrityDTO
  index?: number
}>();

const emits = defineEmits<{
  (e: 'click', value: { id?: string | number, index?: number }): void,
}>();

const { isDesktop, isMounted } = useUiBreakpoints();

const celebrityComp = computed(() => {
  const { data } = props;

  const isSocialAccountDTO = checkSocialAccountDTO(data);

  return {
    id: isSocialAccountDTO ? data.userId : data.userDTO?.id,
    avatarPath: isSocialAccountDTO ? data.avatarPath : data.userDTO?.avatarPath,
    name: isSocialAccountDTO ? data.name : data.userDTO?.name,
    followersCount: isSocialAccountDTO ? data.subscribersCount : data.instagramFollowerCount,
    productsCount: isSocialAccountDTO ? data.productsCount : data.productCount,
    communityBadge: isSocialAccountDTO ? data.communityBadge : data.userDTO?.communityBadge,
    nickname: isSocialAccountDTO ? data.nickname : data.userDTO?.nickName,
  } as PublicProfileDTO;
});

function checkSocialAccountDTO(data: SocialAccountDTO | CelebrityDTO): data is SocialAccountDTO {
  return !!(data as SocialAccountDTO).userId;
}

function handleClick() {
  emits('click', { id: celebrityComp.value.id, index: props.index });
}
</script>

<style scoped lang="scss">
@import "~/assets/scss/settings/index";

.osk-celebrity-card {
  background-color: rgba(0,0,0,.04);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 22px 16px 16px;
  min-height: 309px;

  @include media-query(lg-and-up) {
    padding: 32px 20px 20px;
    min-height: 412px;
  }

  &__img {
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 100px;

    @include media-query(lg-and-up) {
      width: 189px;
      height: 189px;
      border-radius: 189px;
    }
  }

  &__badge {
    max-width: 100%;
    margin-top: -15px;
    margin-bottom: 24px;

    @include media-query(lg-and-up) {
      margin-bottom: 32px;
    }
  }

  &__productsCount {
    @include media-query(lg-and-up) {
      margin-bottom: 8px;
    }
  }

  &__name {
    font-weight: 700;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 12px;
    text-align: center;
  }

  &__button {
    margin-top: auto;
    background: $grey-4;
  }
}
</style>
